export enum Environment {
  DEV = "DEV",
  UAT = "UAT",
  STAGING = "STAGING",
  PRD = "PRD",
}

export enum TestEnvironment {
  PIT = "PIT",
}

export type Tags = {
  BusinessOwner: string;
  SupportOwner: string;
  Department: "244";
  Service: "Nebula";
  CreationMethod: "CloudFormation";
  Environment: Environment | TestEnvironment;
};

export enum SSMParameters {
  BaseStackName = "/website/infra/base-stack-name",
  VpcId = "/website/infra/vpc-id",
}

export type K6StackOutputs = {
  K6Fargate: {
    ClusterName: string;
    TaskDef: string;
    SecurityGroup: string;
    Subnets: string;
  };
};
